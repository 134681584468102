import React from 'react';
import { PageProps, graphql, useStaticQuery } from 'gatsby';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';
// import ReactPlayer from 'react-player';

import { LocaleNameSpaceKeys } from '../../localization/translations';
import Layout, { LayoutOptions, HeroSectionColors } from '../../components/Layout/Layout';
import { useLocaleContext } from '../../context/Locale';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';
import Link from '../../components/Link/Link';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import CommonData from '../../data/common/common';
import { IconTypes } from '../../components/Icon/Icon';
// import DownloadBlock, { DownloadLink } from '../../components/DownloadBlock/DownloadBlock';
import './style.scss';

type Props = PageProps & WithTranslation;

const EliteLoyaltyProgram: React.FC<Props> = (props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();
  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('eliteLoyaltyProgram.seo.title'),
    },
    page: {
      title: t('eliteLoyaltyProgram.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: `hero-image-elite-loyalty-${activeLocaleTag}.png`,
          alt: 'Image',
        },
      },
    },
  };

  const points: Array<any> = t('eliteLoyaltyProgram.pointsBlocks', { returnObjects: true });

  const getRewardPointsRows = () => {
    return points.map((pointArray: any, i: number) => {
      return (
        <tr key={`${pointArray.toString()}-${i}`}>
          {pointArray.map((point: any, j: number) => {
            let className = '';

            if (point.cs === '6') {
              className += 'full';
            }

            if (point.val === '✓') {
              className += 'checkmark';
            }

            return (
              <td
                key={`${point.val}-${i}-${j}`}
                rowSpan={point.rs}
                colSpan={point.cs}
                className={className}
              >
                <Trans t={t} components={{ lt: '<', gt: '>' }}>
                  {point.val}
                  <span className="large" />
                </Trans>
              </td>
            );
          })}
        </tr>
      );
    });
  };

  const firstRowPoints: Array<any> = t('eliteLoyaltyProgram.pointsBlocksFirstRow.badgeOrder', {
    returnObjects: true,
  });

  const {
    allFile: { edges },
    imageEN,
    imageFR,
  } = useStaticQuery(graphql`
    query loyaltyProgramQuery {
      allFile(filter: { relativePath: { regex: "/elite-loyalty/" }, ext: { eq: ".svg" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
      imageEN: file(relativePath: { eq: "elite-loyalty-program/loyalty-en.jpg" }) {
        publicURL
      }
      imageFR: file(relativePath: { eq: "elite-loyalty-program/loyalty-fr.jpg" }) {
        publicURL
      }
    }
  `);

  const imageMapper = edges.reduce((accum: any, curr: any) => {
    const paths = curr.node.name.split('-');
    const next = { ...accum };

    if (next[paths[0]]) {
      next[paths[0]][paths[1]] = curr.node.publicURL;
    } else {
      next[paths[0]] = {
        [paths[1]]: curr.node.publicURL,
      };
    }

    return next;
  }, {});

  const sidebarImage = activeLocaleTag === 'en' ? imageEN.publicURL : imageFR.publicURL;

  const getRewardPointsFirstRow = () => {
    return firstRowPoints.map((point: string, j: number) => (
      <td key={`point-${j}`}>
        <img src={imageMapper[point][activeLocaleTag]} width="80px" height="80px" alt={point} />
      </td>
    ));
  };

  return (
    <Layout options={layoutOptions} className="EliteLoyaltyProgram">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-100">
            <div className="EliteLoyaltyProgram__generic-gap">
              <h2 className="h3">{t('eliteLoyaltyProgram.subHeading')}</h2>
            </div>
            <div className="row row-wrap space-between">
              <div className="column column-66">
                <div className="EliteLoyaltyProgram__generic-gap">
                  <p>
                    <Trans t={t} i18nKey="eliteLoyaltyProgram.introBlock.headingPre" />
                  </p>
                </div>
                <div className="EliteLoyaltyProgram__generic-gap">
                  <p>
                    <Trans i18nKey="eliteLoyaltyProgram.introBlock.description" t={t} />
                  </p>

                  {/* <div className="EliteLoyaltyProgram__player-wrapper EliteLoyaltyProgram__generic-gap">
                    <ReactPlayer
                      url={CommonData.videos.eliteLoyaltyVideoLink[activeLocaleTag]}
                      className="react-player"
                      width="100%"
                      height="100%"
                      controls
                    />
                  </div> */}
                </div>
              </div>
              <div className="column column-25">
                <img src={sidebarImage} alt="loyalty" />
              </div>
            </div>

            <div className="EliteLoyaltyProgram__generic-gap">
              <h2 className="h3" id="loyalty-heading">
                {t('eliteLoyaltyProgram.rewardsInfoBlock.heading')}
              </h2>
              <p>{t('eliteLoyaltyProgram.rewardsInfoBlock.description')}</p>
              <table aria-labelledby="loyalty-heading">
                <tbody>
                  <tr>
                    <td>{t('eliteLoyaltyProgram.pointsBlocksFirstRow.label')}</td>
                    {getRewardPointsFirstRow()}
                  </tr>
                  {getRewardPointsRows()}
                </tbody>
              </table>
            </div>
          </section>
          <section className="column column-100">
            <div className="grid-container">
              <div className="row row-wrap">
                {(t('eliteLoyaltyProgram.threeColumns', {
                  returnObjects: true,
                }) as any[]).map((col) => (
                  <div
                    key={col.title}
                    className="column column-100 EliteLoyaltyProgram__generic-gap"
                  >
                    <h3 className="h6">{col.title}</h3>
                    <p>{col.description}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="EliteLoyaltyProgram__generic-gap">
              <p>
                <Trans i18nKey="eliteLoyaltyProgram.contactBDMFooter" t={t}>
                  <Link to={getPathFromRouteKey(RouteKeys.BROKER_CENTRE.MEET_THE_TEAM)} />
                </Trans>
              </p>
            </div>

            {/* <p>{t('eliteLoyaltyProgram.downloadProgramSheet.hint')}</p>
            <DownloadBlock
              heading={t('eliteLoyaltyProgram.downloadProgramSheet.label')}
              downloadLinks={eliteProgramLinks}
            /> */}
            <p className="small">{t('eliteLoyaltyProgram.footerNote')}</p>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.BROKER_PORTAL)(EliteLoyaltyProgram);
